import React from 'react'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'
import { css } from 'emotion'
import { Layout as _Layout, Text, Color } from '../constants'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Layout from '../components/Layout'

const styles = {
  container: {
    marginTop: 60,
    padding: _Layout.EDGE_PADDING,
  },
  image: {
    maxWidth: 240,
  },
  title: {
    fontWeight: 'bold',
    fontSize: Text.TEXT,
    paddingRight: _Layout.ICON_GAP,
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridGap: 16,
  },
  block: {
    // width: 360,
    // height: 420,
    // border: '1px solid rgb(234, 236, 238)',
    padding: '2em 2em 2em 0',
  },
  date: {
    fontSize: Text.SPAN,
    color: Color.BLACK,
    fontWeight: 'bold',
  },
  description: {
    marginTop: _Layout.INNER_PADDING,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
}

export default class BlogPage extends React.Component {
  render() {
    const { data } = this.props
    const {
      allMarkdownRemark: { edges: posts },
      navbarData,
      footerData,
    } = data

    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <section className={css(styles.container)}>
          <div className="container content">
            <div className="column is-10 is-offset-1">
              <div className="columns">
                <div className="container blog-container">
                  <div className="content">
                    <h1 className="has-text-weight-bold is-size-2">Blog</h1>
                  </div>
                  <div className={css(styles.wrapper)}>
                    {posts
                      .filter(
                        post =>
                          post.node.frontmatter.templateKey === 'blog-post'
                      )
                      .map(({ node: post }) => {
                        return (
                          <div key={post.id} className={css(styles.block)}>
                            <Link to={`blog/${post.frontmatter.path}`}>
                              <PreviewCompatibleImage
                                style={styles.image}
                                imageInfo={post.frontmatter.thumbnail}
                              />
                            </Link>
                            <Link
                              className={css(styles.title)}
                              to={`blog/${post.frontmatter.path}`}
                            >
                              {post.frontmatter.title}
                            </Link>
                            <br />
                            <span className={css(styles.date)}>
                              {post.frontmatter.date}
                            </span>
                            <br />
                            <div className={css(styles.description)}>
                              {post.frontmatter.description}
                            </div>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query BlogQuery {
    ...LayoutFragment
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            description
            thumbnail {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 92) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            path
          }
        }
      }
    }
  }
`
